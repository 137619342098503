let timelineElements = [
    {
        id: 1,
        title: "Data Analyst",
        subtitle: "Health Canada",
        description: "",
        description2: "",
        description3: "",
        description4: "",
        date: "May 2023 - Aug 2023",
        icon: "work",
    },
    {
        id: 2,
        title: "Assistant Product Manager",
        subtitle: "FastLane US",
        description: "- Implemented a knowledgebase and content repository with automations across Microsoft Excel, SharePoint (JSON) and Power Automate.",
        description2: "- Developed a system to cross-reference and identify deviations between vendor websites and our website using web-scraping tools.",
        description3: "- Supported project initiatives across sprint-projects.",
        description4: "- Recommended methods and processes to streamline information flow and communications across departments.",
        date: "May 2022 - Aug 2022",
        icon: "work",
    },
    {
        id: 3,
        title: "Wilfrid Laurier University",
        subtitle: "Computer Science and Business Double Degree",
        description: "",
        description2: "",
        description3: "",
        description4: "",
        date: "Sep 2021 - Apr 2026",
        icon: "school",
    },
    {
        id: 4,
        title: "Cashier/Sales Floor Associate",
        subtitle: "Walmart",
        description: "- Responsible for handling returns and answering questions about merchandise, store policies, and ongoing promotions, as well as ringing up sales on computerized registers.",
        description2: "- Use a computerized inventory system to track and manage stock levels.",
        description3: "- Operate cash register and handle financial transactions with customers.",
        description4: "- Collaborate with other employees to complete more than 200 orders per day.",
        date: "Oct 2020 - Mar 2022",
        icon: "work",
    },
    {
        id: 5,
        title: "Lifeguard",
        subtitle: "Canada's Wonderland Splash Works",
        description: "- Responsible for the safety of clients while providing excellent customer service.",
        description2: "- Standard First Aid and CPR.",
        description3: "- Rescued guests in distress using Ellis & Associates certified procedures.",
        description4: "- Helped de-escalate situations involving guests using problem-solving abilities.",
        date: "Mar 2019 - Aug 2019",
        icon: "work",
    },
    {
        id: 6,
        title: "Math Assistant",
        subtitle: "Kumon",
        description: "- Responsible for marking and assisting students Grades 3 to 9 with academic work.",
        description2: "- Kept track of students’ progress and updated their records accordingly.",
        description3: "- Responsible for maintaining records and reports, and other administrative tasks as required by the center.",
        description4: "",
        date: "Feb 2018 - May 2019",
        icon: "work",
    },
   
  ];
  
  export default timelineElements;